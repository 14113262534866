import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../css/facilities.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { StaticImage } from "gatsby-plugin-image"

export default function IndependencePage(props) {
  return (
    <Layout>
      <Seo title="Independence at Centennial Grove" />
      <div className="contentBox">
        <div className="mobile-back-bttn">
          <AniLink cover to="/facilities">
            Back to Facilities
          </AniLink>
        </div>
        <h1 className="facility-title">Independence at Centennial Grove</h1>
        <div className="facility-container">
          <div className="facility-front-picture-container">
            <StaticImage
              src="../images/facilities/independence/independence-front1.jpg"
              alt="frontyard"
              placeholder="blurred"
            />
          </div>
          <div className="facility-details-container">
            <div className="facility-detail-row">
              <p className="detail-title">EST</p>
              <p className="detail">2006</p>
            </div>
            <div className="facility-detail-row">
              <p className="detail-title">License #</p>
              <p className="detail">157203519</p>
            </div>
            <div className="facility-detail-row">
              <p className="detail-title">Description</p>
              <p className="detail">
                4 bed, Level 4I Specialized (Forensic) adult residential
                facility
              </p>
            </div>
            <div className="facility-detail-row">
              <p className="detail-title">Services</p>
              <p className="detail">
                24 hour supervision, Private rooms, Window/door chimes, 1:1
                supervision in the community, Social skills training,
                Trained/experienced staff, Predominantly male staff, Crises
                Prevention Intervention Supervision by a Licensed Psychiatric
                Technician
              </p>
            </div>
            <div className="facility-detail-row">
              <p className="detail-title">Specialization</p>
              <p className="detail">
                Planning and implementing treatment to developmentally disabled
                individuals who currently are or have a history of being
                involved in criminal activity, mental health problems,
                inappropriate sexual behavior, individuals on probation,
                physical or verbal aggression, property destruction, and
                substance abuse.
              </p>
            </div>
          </div>
        </div>

        <h2 className="pictures-title">Facility Pictures</h2>
        <div className="masonry">
          <div className="item">
            <StaticImage
              src="../images/facilities/independence/independence-living2.jpg"
              alt="living area"
              placeholder="blurred"
              width={500}
              height={650}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/independence/independence-living3.jpg"
              alt="living area"
              placeholder="blurred"
              width={400}
              height={450}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/independence/independence-kitchen2.jpg"
              alt="kitchen"
              placeholder="blurred"
              width={400}
              height={500}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/independence/independence-living1.jpg"
              alt="living area"
              placeholder="blurred"
              width={500}
              height={600}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/independence/independence-living4.jpg"
              alt="living area"
              placeholder="blurred"
              width={400}
              height={450}
            />
          </div>

          <div className="item">
            <StaticImage
              src="../images/facilities/independence/independence-back1.jpg"
              alt="backyard"
              placeholder="blurred"
              width={400}
              height={450}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}
